import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { styles } from './styles'

interface ChooseImageProps {
  images: any
  currentImage: string
  handleUpdate: Function
}

const ChooseImage = ({
  images,
  currentImage,
  handleUpdate,
}: ChooseImageProps) => {
  const [appliedImages, setAppliedImages] = useState([])
  const [finished, setFinished] = useState(false)
  const classes = styles()
  const [checkedImage, setCheckedImage] = useState('')

  useEffect(() => {
    filterImages()
  }, [images])

  useEffect(() => {
    if (finished) {
      appliedImages.forEach((image: string, index: number) => {
        if (image.includes(currentImage)) {
          setCheckedImage(image)
        }
      })
    }
  }, [finished, appliedImages])

  const filterImages = async () => {
    images.forEach((imgSrc: string, index: number) => {
      const img = new Image()
      img.src = imgSrc as string
      img.onload = function() {
        if (img.width > 100 && img.height > 100) {
          setAppliedImages((prev: any) => [...prev, imgSrc])
        }
      }
    })
    setFinished(true)
  }

  const onCheckImage = (e: any, img: string) => {
    e.preventDefault()
    setCheckedImage(img)
  }

  const handleUpdateImage = async (e: any) => {
    e.stopPropagation()
    handleUpdate(checkedImage)
  }

  if (!finished)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress size={40} />
      </div>
    )

  return (
    <div>
      <Grid container className={classes.imagesContainer}>
        {appliedImages.slice(0, 16).map((img: string) => {
          return (
            <div
              className={classes.imageWrapper}
              onClick={e => onCheckImage(e, img)}
            >
              <img
                src={img}
                key={img}
                className={classes.image}
                alt="choose-image"
              />
              <div className={classes.checkboxWrapper}>
                <div className={classes.whiteCircle}></div>
                <div className={classes.round}>
                  <input
                    type="checkbox"
                    id={img}
                    checked={checkedImage === img}
                    onChange={e => onCheckImage(e, img)}
                  />
                  <label htmlFor={img}></label>
                </div>
              </div>
            </div>
          )
        })}
      </Grid>
      <button onClick={handleUpdateImage} className={classes.updateImageButton}>
        update
      </button>
    </div>
  )
}

export default ChooseImage
