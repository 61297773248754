import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import closeMenuIcon from './../../../assets/images/close.svg'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Typography from '@material-ui/core/Typography'
import CHRButton from '../../button'
import CHRInput from '../../input'

interface CreateNewFolderModal {
  handleClose: any
  openStatus: boolean
  shareUrl: string
  handleCreateFolder: Function
  t: TFunction
}

const CreateNewFolderModal = ({
  handleClose,
  openStatus,
  shareUrl,
  t,
  handleCreateFolder,
}: CreateNewFolderModal) => {
  const classes = styles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [value, setValue] = useState()
  const [error, setError] = useState('')

  const handleChange = e => {
    const folderName = e.target.value
    setValue(folderName)
  }

  const onDialogClose = () => {
    handleClose()
    setError('')
    setValue('')
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openStatus}
        onClose={onDialogClose}
        aria-labelledby="createNewFolder"
        className={classes.modal}
      >
        <section className={classes.modalContainer}>
          <button onClick={onDialogClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>
          {shareUrl && (
            <div className={classes.container}>
              <Typography variant="h2" component="h2" className={classes.title}>
                {t('shoppingBoard.createNewFolderTitle')}
              </Typography>
              <div className={classes.shareBox}>
                <div className={classes.formShare}>
                  <div
                    className={`${classes.inputContainer} ${classes.convertedLink}`}
                  >
                    <CHRInput
                      placeholder="name your new folder"
                      customStyles={classes.input}
                      value={value}
                      onChange={handleChange}
                    />
                    <CHRButton
                      label={t('shoppingBoard.createFolderBtn')}
                      hoverEffects={true}
                      onClick={() =>
                        handleCreateFolder(value).catch(err => {
                          if (err.response?.status === 409)
                            setError(err.response.data.message)
                          else setError(t('messages.somethingWentWrong'))
                        })
                      }
                    />
                  </div>
                </div>
                {error && <p className={classes.errMsg}>{error}</p>}
              </div>
            </div>
          )}
        </section>
      </Dialog>
    </>
  )
}

export default withTranslation()(CreateNewFolderModal)
