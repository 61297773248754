import Auth from '@aws-amplify/auth'
import { Grid, Typography } from '@material-ui/core'
import { useParams } from '@reach/router'
import { navigate } from 'gatsby'
import { TFunction } from 'i18next'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { TrackingCase } from '../../../../utils/trackingCases'
import { useAppState } from '../../../appState'
import chirpyestIcon from '../../../assets/images/chirpyest-logo.svg'
import editIcon from '../../../assets/images/edit-info.svg'
import axios from '../../../axios'
import { ENDPOINTS, ROUTES } from '../../../constants'
import { tracker } from '../../../systemLogs'
import CHRButton from '../../button'
import IOSSwitch from '../../IOSSwitch'
import CHRSectionContainer from '../../sectionContainer'
import CHRSpinner from '../../spinner'
import FolderView from '../foldersView'
import GridItemsStatic from '../gridItemsStatic'
import GridPaging from '../paging'
import ContentContainer from './../../../components/contentContainer'
import BecomeInfluencer from './../becomeInfluencer'
import BoardEmpty from './../boardEmpty'
import BoardProfile from './../boardProfile'
import AddingModal from './addingModal'
import { styles } from './styles'
import { errorToast } from '../../../../utils/errToast'
import SEO from '../../seo'

interface ListingBoardProps {
  t: TFunction
  image: any
}

const ListingBoard = ({ t, image }: ListingBoardProps) => {
  const classes = styles()

  const [appState, dispatch] = useAppState()
  const [defaultSort, setDefaultSort] = useState('position')
  const [shoppingBoardItems, setShoppingBoardItems] = useState([])
  const [shareLink, setShareLink] = useState('')
  const [items, setItems] = useState([])
  const [canEdit, setEdit] = useState(false)
  const [updatePositionItems, setUpdatePositionItems] = useState([])
  const [userInfo, setUserInfo] = useState({})
  const [shouldShowSelect, setShouldShowSelect] = useState(false)
  const [productsChecked, setProductChecked] = useState({})
  const [open, setOpen] = useState(false)

  const params = useParams()

  const options = [
    { value: 1, label: t('shoppingBoard.trending') },
    { value: 2, label: t('shoppingBoard.price') },
    { value: 3, label: t('shoppingBoard.name') },
    { value: 4, label: t('shoppingBoard.latest') },
    { value: 6, label: t('shoppingBoard.customPosition') },
    { value: 5, label: t('shoppingBoard.cashpack') },
  ]

  const nonUserOptions = [
    { value: 1, label: t('shoppingBoard.trending') },
    { value: 2, label: t('shoppingBoard.price') },
    { value: 3, label: t('shoppingBoard.name') },
    { value: 4, label: t('shoppingBoard.latest') },
    { value: 6, label: t('shoppingBoard.customPosition') },
  ]

  const [selectedSort, setSort] = useState(options[4])

  const [pages, setPages] = useState(0)
  const perPage = 48
  const [selectedPage, setSelectedPage] = useState(1)
  const [isLoadMore, setLoadMore] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isfolderView, setIsFolderView] = useState(false)
  const [shouldShowPublic, setShouldShowPublic] = useState(true)
  const [folders, setFolders] = useState([])
  const [error, setError] = useState('')

  useEffect(() => {
    ;(userInfo?.isPro || userInfo?.isCreator) && getFolders()
  }, [userInfo])

  const getFolders = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(
        ENDPOINTS.sbFolders.replace(':username', params.userName)
      )

      const sortedFolders = data.folders.map(folder => {
        const sortedItems = folder.items.sort(
          (a: any, b: any) => a.position - b.position
        )
        return { ...folder, items: sortedItems }
      })
      setFolders(sortedFolders)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const handleChangePage = (page: number) => {
    setLoadMore(false)
    setSelectedPage(page)
    window.scroll({
      top: 200,
      behavior: 'auto',
    })
  }

  const handleLoadMore = (page: number) => {
    setSelectedPage(page)
    setLoadMore(true)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSortChange = (event: any) => {
    setSort(event)
    let newItems = shoppingBoardItems
    switch (event.value) {
      case 1: {
        newItems = _.orderBy(shoppingBoardItems, ['trendingCount'], ['desc'])
        break
      }
      case 2: {
        newItems = _.orderBy(
          shoppingBoardItems,
          item => Number(item.data.price.slice(1)),
          ['desc']
        )
        break
      }
      case 3: {
        newItems = _.orderBy(shoppingBoardItems, ['data.name'], ['asc'])
        break
      }
      case 4: {
        newItems = _.orderBy(shoppingBoardItems, ['id'], ['desc'])
        break
      }
      case 5: {
        newItems = _.orderBy(
          shoppingBoardItems,
          item =>
            Number(item.data.cashBack.slice(0, item.data.cashBack.length - 1)),
          ['desc']
        )
        break
      }
      case 6: {
        newItems = _.orderBy(shoppingBoardItems, ['position'], ['asc'])
        break
      }
      default:
        break
    }
    const itemsWithPaging = settingPages(newItems)
    setShoppingBoardItems(itemsWithPaging)
    setSelectedPage(1)
  }

  const handleRemoveItem = async (i: any) => {
    setItems(_.reject(items, { i: i }))
    tracker.track(
      TrackingCase.UserTracking,
      `DELETE ${ENDPOINTS.deleteShoppingBoardItem}`,
      { location: 'ListingBoard', itemId: i }
    )
    try {
      await axios.delete(ENDPOINTS.deleteShoppingBoardItem.replace(':id', i))
      const newItems = _.reject(shoppingBoardItems, { id: i })
      const itemsWithPaging = settingPages(newItems)
      setShoppingBoardItems(itemsWithPaging)
      const oldPage = selectedPage
      const totalPages = _.ceil(itemsWithPaging.length / perPage)
      if (oldPage >= totalPages) {
        setSelectedPage(totalPages)
        setPages(totalPages)
      }
    } catch (error) {
      errorToast(`couldn't remove item, please try again`)
    }
  }

  const settingPages = (items: any) => {
    let newItems: any[] = []
    items.map((item: any, index: number) => {
      const num = index + 1
      const obj = item
      obj.page = _.ceil(num / perPage)
      obj.customPosition = num
      newItems.push({ obj })
    })
    return items
  }

  const [isMoved, setIsMoved] = useState(
    window.localStorage.getItem('movedItems') ?? false
  )

  const reloadShopping = async () => {
    try {
      let userName = params.userName
      let endpoint = ENDPOINTS.shoppingBoardNonUser.replace(':id', userName)
      const currentUserInfo = await Auth.currentCredentials()
      // userId = await currentSession.getIdToken().payload['custom:user_id']
      const {
        data: { data },
      } = await axios.get(`${endpoint}`)
      const sbBoardItems = data || []
      setPages(sbBoardItems < 1 ? 0 : _.ceil(sbBoardItems.length / perPage))
      let trendingItems: any[] = []
      trendingItems = sbBoardItems
        ? _.orderBy(sbBoardItems, ['id'], ['desc'])
        : []
      setSort(options[4])
      const itemsWithPaging = settingPages(trendingItems)
      setShoppingBoardItems(itemsWithPaging)
      setSelectedPage(1)
    } catch (err) {
      // TODO: handle error, this function not used currently
    }
  }

  useEffect(() => {
    const userName = params.userName
    setIsLoading(true)
    if (appState.username || userName) {
      window.location.hash === '#folders' && setIsFolderView(true)
      window.addEventListener('hashchange', () =>
        setIsFolderView(!!(window.location.hash === '#folders'))
      )
      const endpoint = ENDPOINTS.shoppingBoardNonUser.replace(':id', userName)
      if (appState.username === userName) {
        setEdit(true)
      } else {
        setEdit(false)
      }
      const getShoppingBoard = async () => {
        try {
          const {
            data: { data },
          } = await axios.get(`${endpoint}`)

          const sbBoardItems = data?.shoppingBoardItems || []
          setUserInfo(data.userInfo)
          setShouldShowPublic(data.userInfo?.isPublicShoppingBoard)
          if (
            data.userInfo?.isPublicShoppingBoard === false &&
            appState.username !== params.userName
          ) {
            setIsFolderView(true)
            navigate(`/s/${params.userName}#folders`)
          }
          setPages(sbBoardItems < 1 ? 0 : _.ceil(sbBoardItems.length / perPage))
          let trendingItems: any[] = []
          if (defaultSort === 'id') {
            trendingItems = sbBoardItems
              ? _.orderBy(sbBoardItems, ['id'], ['desc'])
              : []
          } else if (isMoved || defaultSort === 'position') {
            setSort(options[4])
            trendingItems = sbBoardItems
              ? _.orderBy(sbBoardItems, ['position'], ['asc'])
              : []
          } else {
            trendingItems = sbBoardItems
              ? _.orderBy(sbBoardItems, ['trendingCount'], ['desc'])
              : []
          }
          const itemsWithPaging = settingPages(trendingItems)
          setShoppingBoardItems(itemsWithPaging)
          setSelectedPage(1)
          setIsLoading(false)
        } catch (error) {
          if (error.response?.status === 404) {
            setIsLoading(false)
          } else {
            setIsLoading(false)
            setError(t('messages.somethingWentWrong'))
          }
        }
      }

      getShoppingBoard()
    }
  }, [dispatch, params.userName, appState.username])

  // TODO: this logic in not used anywhere, remove it
  useEffect(() => {
    if (updatePositionItems.length > 0) {
      var newItems = updatePositionItems
      updatePositionItems.map((item: any, index: number) => {
        if (item.status === 0) {
          try {
            ;(async () => {
              newItems[index]['status'] = 1
              setUpdatePositionItems(newItems)
              const {
                data: { data },
              } = await axios.put(ENDPOINTS.updatePositionShoppingBoardItem, {
                data: item.data,
              })
              if (data) {
                newItems[index]['status'] = -1
                setUpdatePositionItems(newItems)
              }
            })().catch(err => {})
          } catch (error) {}
        }
      })
    }
  }, [updatePositionItems])

  const handleUpdateShortLink = (item, shortLink, trackingLink) => {
    setShoppingBoardItems(items => {
      const i = items.findIndex(e => e.id === item.id)
      return [
        ...items.slice(0, i),
        {
          ...items[i],
          data: {
            ...items[i].data,
            shortLink,
            trackingLink,
          },
        },
        ...items.slice(i + 1),
      ]
    })
  }

  const handleSBoardItemsChange = (item, action) => {
    const itemsCount =
      action === 'added'
        ? shoppingBoardItems.length + 1
        : shoppingBoardItems.length - 1
    setPages(itemsCount < 1 ? 0 : _.ceil(itemsCount / perPage))
    setSelectedPage(1)
    setShoppingBoardItems(pre => {
      const sbBoardItems = JSON.parse(JSON.stringify(pre))
      let trendingItems: any[] = []

      if (action === 'added') {
        sbBoardItems.push(item)
      } else {
        const index = sbBoardItems.findIndex(e => e.data.post_id === item.id)
        if (index > -1) {
          sbBoardItems.splice(index, 1)
        }
      }

      if (defaultSort === 'id') {
        trendingItems = sbBoardItems
          ? _.orderBy(sbBoardItems, ['id'], ['desc'])
          : []
      } else if (isMoved || defaultSort === 'position') {
        setSort(options[4])
        trendingItems = sbBoardItems
          ? _.orderBy(sbBoardItems, ['position'], ['asc'])
          : []
      } else {
        trendingItems = sbBoardItems
          ? _.orderBy(sbBoardItems, ['trendingCount'], ['desc'])
          : []
      }
      const itemsWithPaging = settingPages(trendingItems)
      return itemsWithPaging
    })
  }

  const onCheckFolder = (id, value) => {
    setProductChecked({ ...productsChecked, [id]: value })
  }

  const onCancelSelect = () => {
    setShouldShowSelect(false)
    setProductChecked({})
  }

  const onRemoveFolder = async id => {
    try {
      tracker.track(TrackingCase.UserTracking, `DELETE ${ENDPOINTS.SBFolder}`, {
        location: 'ListingBoard',
        SBFolderId: id,
      })
      await axios.delete(ENDPOINTS.SBFolder.replace(':id', id))
      getFolders()
    } catch (err) {
      errorToast(`couldn't remove folder, please try again`)
    }
  }

  const handleCreateFolder = async name => {
    await axios.post(ENDPOINTS.createSBFolder, {
      name,
      description: 'fashion', // TODO: should entered by the user
      isPublic: true,
    })
    getFolders()
  }

  const handleUpdatePosition = async (
    itemId: number,
    itemPosition: number,
    targetIndex: any
  ) => {
    try {
      const newPosition = shoppingBoardItems[targetIndex].position
      if (newPosition !== itemPosition) {
        await axios.post(ENDPOINTS.SBUpdatePosition, {
          id: itemId,
          newPosition: newPosition,
          currentPosition: itemPosition,
        })

        setShoppingBoardItems(s => {
          let items
          if (newPosition > itemPosition) {
            items = shoppingBoardItems.map(item => ({
              ...item,
              position:
                item.id === itemId
                  ? newPosition
                  : item.position > itemPosition && item.position <= newPosition
                  ? item.position - 1
                  : item.position,
            }))
          } else {
            items = shoppingBoardItems.map(item => ({
              ...item,
              position:
                item.id === itemId
                  ? newPosition
                  : item.position >= newPosition && item.position < itemPosition
                  ? item.position + 1
                  : item.position,
            }))
          }

          return _.orderBy(items, ['position'], ['asc'])
        })
      }
    } catch (err) {
      errorToast(`couldn't update position, please try again`)
    }
  }

  const handleChangeIsPublic = async () => {
    try {
      setShouldShowPublic(!shouldShowPublic)
      await axios.patch(ENDPOINTS.user.replace(':id', appState.userId), {
        isPublicShoppingBoard: !shouldShowPublic,
      })
    } catch (err) {
      setShouldShowPublic(s => !s)
    }
  }

  if (isLoading) {
    return <CHRSpinner />
  }

  if (error || _.isEmpty(userInfo)) {
    return (
      <CHRSectionContainer>
        <ContentContainer>
          <div className={classes.container404}>
            <img
              src={chirpyestIcon}
              alt={t('shared.chirpyest')}
              className={classes.logo}
            />
            <Typography variant="h1" align="center">
              {error ? error : t('notFound.title')}
            </Typography>
            <CHRButton
              label={t('notFound.buttonText')}
              onClick={() => navigate(ROUTES.home)}
              customStyle={classes.button404}
            />
          </div>
        </ContentContainer>
      </CHRSectionContainer>
    )
  }

  return (
    <>
      <SEO
        title={t('seo.users.title').replace('[Username]', params.userName)}
        description={t('seo.users.description')
          .replace('[Username]', params.userName)
          .replace('[Username]', params.userName)}
      />
      <BoardProfile
        shoppingBoardItems={shoppingBoardItems}
        handleSortChange={handleSortChange}
        options={options}
        nonUserOptions={nonUserOptions}
        selectedSort={selectedSort}
        shareUrl={shareLink}
        canEdit={canEdit}
        reloadShopping={reloadShopping}
        shoppingUserProfile={{
          ...userInfo,
          imageKey: userInfo.image,
          imageUrl: '',
        }}
        handleSBoardItemsChange={handleSBoardItemsChange}
        folderView={(userInfo?.isPro || userInfo?.isCreator) && isfolderView}
        handleCreateFolder={handleCreateFolder}
      />

      <ContentContainer customStyle={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.summaryItems}>
            {((shoppingBoardItems && shoppingBoardItems.length > 0) ||
              (folders && folders.length > 0)) &&
              ((userInfo?.isPro || userInfo?.isCreator) && isfolderView ? (
                <FolderView
                  onChange={setIsFolderView}
                  isFolderView={isfolderView}
                  shouldShowPublic={shouldShowPublic}
                  onChangePublic={setShouldShowPublic}
                  folders={folders}
                  allItems={shoppingBoardItems.slice(0, 3)}
                  handleRemoveFolder={onRemoveFolder}
                  canEdit={canEdit}
                />
              ) : (
                <>
                  {(userInfo?.isPro || userInfo?.isCreator) && (
                    <Grid
                      container
                      justifyContent="space-between"
                      className={classes.subGrid}
                    >
                      <Typography variant="h4">
                        {shouldShowSelect ? 'select items to add' : 'all items'}
                      </Typography>

                      {!shouldShowSelect ? (
                        <div
                          className={`${classes.subBtns} ${classes.bottomMargin}`}
                        >
                          <CHRButton
                            label="back to all folders"
                            onClick={() => {
                              setIsFolderView(true)
                              window.location.hash = 'folders'
                            }}
                            customStyle={classes.btn}
                            hoverEffects={true}
                          />
                          {canEdit && (
                            <CHRButton
                              icon={editIcon}
                              label="add items to a folder"
                              onClick={() => setShouldShowSelect(true)}
                              customStyle={`${classes.btn} ${classes.btnOther}`}
                              hoverEffects={true}
                            />
                          )}
                        </div>
                      ) : (
                        <div className={classes.subBtns}>
                          <CHRButton
                            label="add selected to folder"
                            onClick={handleOpen} //open the modal
                            customStyle={classes.btn}
                            hoverEffects={true}
                          />
                          <CHRButton
                            label="cancel"
                            onClick={onCancelSelect}
                            customStyle={`${classes.btn} ${classes.btnOther}`}
                            hoverEffects={true}
                          />
                        </div>
                      )}
                    </Grid>
                  )}
                  <Grid
                    container
                    justifyContent="space-between"
                    className={classes.perItemsContainer}
                  >
                    <Typography component="p" className={classes.perItems}>
                      {shoppingBoardItems.length} item
                      {shoppingBoardItems.length === 1 ? '' : 's'}
                    </Typography>
                    {(userInfo?.isPro || userInfo?.isCreator) && canEdit && (
                      <div className={classes.switchBtn}>
                        <Typography variant="button" component="span">
                          public folder
                        </Typography>
                        <IOSSwitch
                          checked={shouldShowPublic}
                          onChange={handleChangeIsPublic}
                          name="publicFolders"
                        />
                      </div>
                    )}
                  </Grid>
                  <GridItemsStatic
                    shoppingUserProfile={{
                      ...userInfo,
                      imageKey: userInfo.image,
                      imageUrl: '',
                    }}
                    shoppingBoardItems={shoppingBoardItems}
                    handleRemoveItem={handleRemoveItem}
                    handleUpdateShortLink={handleUpdateShortLink}
                    handleUpdatePosition={handleUpdatePosition}
                    canEdit={canEdit}
                    selectedPage={selectedPage}
                    isLoadMore={isLoadMore}
                    selectedSort={selectedSort}
                    showSelect={shouldShowSelect}
                    onCheckFolder={onCheckFolder}
                  />
                  <GridPaging
                    onChange={handleChangePage}
                    handleLoadMore={handleLoadMore}
                    selected={selectedPage}
                    pages={pages}
                  />
                </>
              ))}
            {((shoppingBoardItems &&
              shoppingBoardItems.length < 1 &&
              folders &&
              folders.length < 1) ||
              shoppingBoardItems === null) &&
              (canEdit ? (
                <BoardEmpty hasUsername={!!appState.username} />
              ) : (
                <Typography variant="h3">
                  {t('shoppingBoard.noProducts')}
                </Typography>
              ))}
          </div>
        </div>
        {!canEdit && (
          <BecomeInfluencer
            image={image}
            shoppingUserProfile={{
              ...userInfo,
              imageKey: userInfo.image,
              imageUrl: '',
            }}
            t={t}
          />
        )}
      </ContentContainer>
      <AddingModal
        folders={folders}
        products={productsChecked}
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        hadleAfterSubmit={onCancelSelect}
        refreshFolders={getFolders}
      />
    </>
  )
}
export default withTranslation()(ListingBoard)
