import React, { useRef, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import closeMenuIcon from './../../../assets/images/close.svg'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
// import CardActionArea from '@material-ui/core/CardActionArea'
import Typography from '@material-ui/core/Typography'
import CHRButton from '../../button'
import CHRInput from '../../input'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
} from 'react-share'
import emailIcon from './../../../assets/images/dark-email.svg'
import emailIconHover from './../../../assets/images/white-email.svg'
import facebookIcon from './../../../assets/images/facebook.svg'
import facebookIconHover from './../../../assets/images/social/facebook-white.svg'
import twiterIcon from './../../../assets/images/twitter.svg'
import twiterIconHover from './../../../assets/images/social/twitter-white.svg'
import smsIcon from './../../../assets/images/text.svg'
import smsIconHover from './../../../assets/images/social/text-white.svg'
import pinterestIcon from './../../../assets/images/pinterest.svg'
import pinterestIconHover from './../../../assets/images/social/pinterest-white.svg'
import { isIOS, isMacOs } from 'react-device-detect'
import { printestDefaultImageURL } from '../../../constants'
// import { generatePinterestShareLink } from '../../../../utils/generatePinterestShareLink'

interface ShareShoppingBoardModal {
  handleClose: any
  openStatus: boolean
  shareUrl: string
  t: TFunction
}

const ShareShoppingBoardModal = ({
  handleClose,
  openStatus,
  shareUrl,
  t,
}: ShareShoppingBoardModal) => {
  const classes = styles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const textAreaRef = useRef(null)
  const [currentMessage, setCurrentMessage] = useState({
    text: '',
    error: false,
  })
  const [subject, setSubject] = useState('My Chirpyest Shopping Board')
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = (event: any) => {
    setIsCopied(true)
    if (textAreaRef && textAreaRef?.current !== null) {
      textAreaRef?.current?.select()
      document.execCommand('copy')
      event?.target?.focus()
      const successMessage = t('messages.copiedToClipboard')
      setCurrentMessage({ text: successMessage, error: false })
    } else {
      const failMessage = t('messages.couldNotSelectText')
      setCurrentMessage({ text: failMessage, error: false })
    }
  }

  const handleSMSButtonClick = (event: any) => {
    if (isIOS || isMacOs) {
      window.open(
        `sms:?&body=${t('shoppingBoard.shareOnSMS') + shareUrl}`,
        '_self'
      )
    } else {
      window.open(
        `sms:?body=${t('shoppingBoard.shareOnSMS') + shareUrl}`,
        '_self'
      )
    }
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openStatus}
        onClose={handleClose}
        aria-labelledby="shareShoppingBoard"
        className={classes.modal}
      >
        <section className={classes.modalContainer}>
          <button onClick={handleClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>
          {shareUrl && (
            <div className={classes.container}>
              <Typography variant="h2" component="h2" className={classes.title}>
                {t('shoppingBoard.shareTitle')}
              </Typography>
              <Typography component="p" className={classes.des}>
                {t('shoppingBoard.shareDescription')}
              </Typography>
              <div className={classes.shareBox}>
                <div className={classes.formShare}>
                  <div
                    className={`${classes.inputContainer} ${classes.convertedLink}`}
                  >
                    <CHRInput
                      placeholder=""
                      value={shareUrl}
                      onChange={() => shareUrl}
                      elementRef={textAreaRef}
                      customStyles={classes.input}
                    />
                    {document.queryCommandSupported('copy') && (
                      <CHRButton
                        label={
                          !isCopied
                            ? t('shoppingBoard.copyBtn')
                            : t('messages.copiedToClipboard')
                        }
                        onClick={copyToClipboard}
                        customStyle={classes.btnCopy}
                        hoverEffects={true}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.viaSocials}>
                  <Typography component="p" className={classes.viaShort}>
                    {t('shoppingBoard.shareVia')}
                  </Typography>
                  <div className={classes.viaLinks}>
                    <div className={`${classes.socialButton}`}>
                      <button
                        className={`${classes.smsButton} ${classes.secondBtn}`}
                        onClick={handleSMSButtonClick}
                      >
                        <img
                          src={smsIcon}
                          alt={t('shared.sms')}
                          className={`icon-default ${classes.socialIcon}`}
                        />
                        <img
                          src={smsIconHover}
                          alt={t('shared.sms')}
                          className={`icon-hover`}
                        />
                        <span className={classes.socialText}>{`${t(
                          'shared.shareBy'
                        )} ${t('shared.sms')}`}</span>
                      </button>
                    </div>
                    <div className={`${classes.socialButton}`}>
                      <EmailShareButton
                        subject={subject}
                        body={t('shoppingBoard.shareOnEmail')}
                        url={shareUrl}
                        className={classes.secondBtn}
                      >
                        <img
                          src={emailIcon}
                          alt={t('shared.email')}
                          className={`icon-default ${classes.socialIcon}`}
                        />
                        <img
                          src={emailIconHover}
                          alt={t('shared.email')}
                          className={`icon-hover`}
                        />
                        <span className={classes.socialText}>{`${t(
                          'shared.shareBy'
                        )} ${t('shared.email')}`}</span>
                      </EmailShareButton>
                    </div>
                    <div className={`${classes.socialButton}`}>
                      <FacebookShareButton
                        url={shareUrl}
                        quote={t('shoppingBoard.shareOnFacebook')}
                        className={classes.secondBtn}
                      >
                        <img
                          src={facebookIcon}
                          alt={t('imageAlts.facebook')}
                          className={`icon-default ${classes.socialIcon}`}
                        />
                        <img
                          src={facebookIconHover}
                          alt={t('imageAlts.facebook')}
                          className={`icon-hover`}
                        />
                        <span className={classes.socialText}>{`${t(
                          'shared.shareOn'
                        )} ${t('imageAlts.facebook')}`}</span>
                      </FacebookShareButton>
                    </div>
                    <div className={`${classes.socialButton}`}>
                      <TwitterShareButton
                        url={shareUrl}
                        title={t('shoppingBoard.shareOnTwitter')}
                        className={classes.secondBtn}
                      >
                        <img
                          src={twiterIcon}
                          alt={t('imageAlts.twitter')}
                          className={`icon-default ${classes.socialIcon}`}
                        />
                        <img
                          src={twiterIconHover}
                          alt={t('imageAlts.twitter')}
                          className={`icon-hover`}
                        />
                        <span className={classes.socialText}>{`${t(
                          'shared.shareOn'
                        )} ${t('imageAlts.twitter')}`}</span>
                      </TwitterShareButton>
                    </div>
                    <div className={`${classes.socialButton}`}>
                      <PinterestShareButton
                        url={shareUrl}
                        description={t('shoppingBoard.shareOnPinterest')}
                        media={printestDefaultImageURL}
                        className={classes.secondBtn}
                      >
                        <img
                          src={pinterestIcon}
                          alt={t('imageAlts.pinterest')}
                          className={`icon-default ${classes.socialIcon}`}
                        />
                        <img
                          src={pinterestIconHover}
                          alt={t('imageAlts.pinterest')}
                          className={`icon-hover`}
                        />
                        <span className={classes.socialText}>{`${t(
                          'shared.shareOn'
                        )} ${t('imageAlts.pinterest')}`}</span>
                      </PinterestShareButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </Dialog>
    </>
  )
}

export default withTranslation()(ShareShoppingBoardModal)
