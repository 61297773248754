import React from 'react'
import {
  Dialog,
  Box,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import closeMenuIcon from '../../../assets/images/close.svg'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import CHROutlinedButton from '../../outlinedButton'

interface ConnectInstagramModalProps {
  handleClose: any
  openStatus: boolean
  socialUrls: any
  userId: any
  handleAfterConnect: Function
  t: TFunction
}

const SwitchAccountsModal = ({
  handleClose,
  openStatus,
  t,
}: ConnectInstagramModalProps) => {
  const classes = styles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openStatus}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <section className={classes.dialogContainer}>
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.dialogTitleSection}
        >
          <button onClick={handleClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>
          <Typography variant="h2">switch accounts</Typography>
        </DialogTitle>
        <Typography variant="body1">
          after switching accounts, go back to chirpyest and click add from
          instagram
        </Typography>
        <Box mt={2} />
        <CHROutlinedButton
          label="switch accounts"
          onClick={() => {
            window.localStorage.removeItem('ig_token')
            window
              .open(
                'https://www.instagram.com/accounts/login/?next=/accounts/logout/',
                '_blank'
              )
              .focus()
            handleClose()
          }}
        />
      </section>
    </Dialog>
  )
}

export default withTranslation()(SwitchAccountsModal)
