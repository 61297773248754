import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, FormControl } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import CloseIcon from '@material-ui/icons/Close'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { BREAKPOINTS, FONTS } from '../../../constants'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Input from '../../input'
import Button from '../../button'
import axios from '../../../axios'
import { ENDPOINTS, COLORS } from '../../../constants'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: 376,
    backgroundColor: theme.palette.common.white,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 26,
    paddingBottom: 26,
    margin: 'auto',
    marginTop: '10%',
  },
  closeBtn: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
  },
  title: {
    marginBottom: 40,
  },
  formControl: {
    width: '100%',
    minWidth: 110,
    maxWidth: 343,
    position: 'relative',
    backgroundColor: '#FAFBFD',
  },
  sortByText: {
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '0.75rem',
    fontWeight: 400,
    position: 'absolute',
    bottom: 7.5,
    left: 0,
    margin: 0,
  },
  selectList: {
    ['& > select']: {
      padding: 0,
      paddingLeft: 10,
      paddingRight: 0,
      backgroundColor: 'unset !important',
      fontFamily: FONTS.Graphik.GraphikRegular,
      fontSize: '0.75rem',
      lineHeight: 'normal',
      ['& > option']: {
        fontFamily: FONTS.Graphik.GraphikRegular,
      },
    },
  },
  createText: {
    textAlign: 'center',
    paddingTop: 20,
  },
  btn: {
    paddingTop: 40,
  },
  errorMsg: {
    color: COLORS.dangerRed,
    fontSize: 14,
    margin: 0,
  },
}))

const AddingModal = ({
  folders,
  products,
  open,
  handleOpen,
  handleClose,
  hadleAfterSubmit,
  refreshFolders,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [selectedOption, setSelectedOption] = useState({
    value: null,
    label: null,
  })
  const [newFolderName, setNewFolderName] = useState(null)
  const [isAdding, setIsAdding] = useState(false)
  const [error, setError] = useState('')

  const producIds = Object.keys(products)
    .filter(item => products[item])
    .map(id => Number(id))

  const handleChange = event => {
    setNewFolderName('')
    setSelectedOption(event)
  }

  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'transparent',
      width: '100%',
      padding: 0,
      marginLeft: 10,
      cursor: 'pointer',
      border: 'none !important',
      boxShadow: 'none',
      '&:hover': {
        border: 'none !important',
      },
    }),

    option: styles => {
      return {
        ...styles,
        fontFamily: 'Graphik Regular',
        fontSize: '1rem',
        padding: '5px 0 5px 55px',
        [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
          fontSize: '1.25rem',
        },
      }
    },

    valueContainer: styles => ({
      height: 50,
    }),

    singleValue: styles => ({
      ...styles,
      top: 'unset',
      bottom: -25,
      fontFamily: 'Graphik Regular',
      fontSize: '0.75rem',
      fontWeight: 400,
      color: 'transparent',
    }),

    placeholder: styles => ({
      display: 'none',
    }),

    input: styles => ({
      color: 'transparent',
    }),

    menu: styles => ({
      ...styles,
      width: '100%',
      left: 0,
    }),
  }

  const onChangeName = e => {
    const value = e.target.value
    setSelectedOption({
      value: null,
      label: null,
    })
    setNewFolderName(value)
  }

  useEffect(() => {
    setNewFolderName(null)
    setError('')
    setSelectedOption({
      value: null,
      label: null,
    })
  }, [open])

  const handleAddProducts = async () => {
    try {
      if (!producIds.length) {
        handleClose()
      } else if (newFolderName || selectedOption.value) {
        setIsAdding(true)
        //TODO: add the products -- API request
        if (newFolderName) {
          const {
            data: {
              data: { id },
            },
          } = await axios.post(ENDPOINTS.createSBFolder, {
            name: newFolderName,
            description: 'fashion', // TODO: should entered by the user
            isPublic: true,
          })
          await postProducts(id)
        } else if (selectedOption.value) {
          await postProducts(selectedOption.value)
        }
        refreshFolders()
        setIsAdding(false)
        hadleAfterSubmit()
        handleClose()
      }
    } catch (err) {
      setIsAdding(false)
      if (err.response?.status === 409) setError(err.response.data.message)
      else setError(t('messages.somethingWentWrong'))
    }
  }

  const postProducts = async id => {
    const data = await axios.post(
      ENDPOINTS.addProductsToFolder.replace(':id', id),
      {
        items: producIds,
      }
    )
  }

  const options = folders.map(folder => ({
    value: folder.folderId,
    label: folder.name,
  }))

  const onModalClose = () => {
    handleClose()
    setError('')
    setSelectedOption({ value: null, label: null })
  }

  return (
    <>
      <Modal
        open={open}
        onClose={onModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <div className={classes.closeBtn} onClick={onModalClose}>
            <CloseIcon fontSize="small" />
          </div>
          <Typography variant="h2" className={classes.title}>
            select a folder to add products to or create a new one
          </Typography>
          <FormControl className={classes.formControl}>
            <h4 className={classes.sortByText}>
              {selectedOption.label || 'select existing folder'}
              <ArrowDropDownIcon />
            </h4>
            <Select
              value={selectedOption}
              className={classes.selectList}
              onChange={handleChange}
              options={options}
              styles={colourStyles}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
            />
          </FormControl>
          <Typography variant="subtitle1" className={classes.createText}>
            or add a title below to create a new one:
          </Typography>
          <Input
            value={newFolderName}
            placeholder="name your new folder"
            onChange={onChangeName}
          />
          <div className={classes.btn}>
            <Button
              label={`add ${producIds.length} products`} // TODO: make the number dynamic
              onClick={handleAddProducts}
              isSubmitting={isAdding}
              disabled={isAdding}
            />
          </div>
          {error && <p className={classes.errorMsg}>{error}</p>}
        </div>
      </Modal>
    </>
  )
}

export default AddingModal
