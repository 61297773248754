import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, FONTS, COLORS } from '../../../constants'

export const styles = makeStyles(theme => ({
  container: {
    width: '100%',
    overflow: 'hidden',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
    },
  },
  wrapper: {},
  perItems: {
    display: 'none',
    fontFamily: FONTS.Graphik.GraphikRegular + ' !important',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontSize: '1rem',
    lineHeight: '1.125',
    marginBottom: '42px !important',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'block',
    },
  },
  summaryItems: {},
  container404: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 960,
    margin: '0px auto',
  },
  logo: {
    display: 'block',
    marginBottom: 40,
  },
  button404: {
    maxWidth: 460,
  },
  btn: {
    background: 'none',
    minWidth: 210,
    color: theme.palette.common.black,
    transition: '0.5s',
    maxWidth: 263,
    margin: 0,
    ['&:focus']: {
      outline: 'none',
    },
    ['& > p']: {
      color: theme.palette.common.black,
    },
    ['&:hover']: {
      color: theme.palette.common.white,
      background: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      ['& > p']: {
        color: theme.palette.common.white,
      },
    },
    ['& > img']: {
      width: '14px',
      height: 'auto',
      left: '19px',
    },

    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      width: '100%',
      marginBottom: 15,
    },
  },

  subGrid: {
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  privateWrapper: {
    maxWidth: 668,
    width: '100%',
    margin: '120px auto 0',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      marginTop: 60,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  shoppingLink: {
    color: '#000',
  },
  inputNameWrapper: {
    position: 'relative',
  },
  nameInput: {
    width: '100%',
    display: 'inline',
    padding: '5px 0',
    fontSize: '2.8125rem',
    lineHeight: '1.15',
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0,
    zIndex: -1,
    letterSpacing: 0,
    minWidth: 100,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      fontSize: '3.75rem',
      lineHeight: '1.17',
      margin: '0 0 10px',
      textTransform: 'lowercase',
      top: -5,
      left: 2,
    },
  },
  nameInputShow: {
    zIndex: 0,
    opacity: 10,
    backgroundColor: '#FFF',
    border: 'none',
  },
  nameLabel: {
    fontSize: '0.875rem',
    color: '#CDCDCD',
    padding: '6px 0',
    boxShadow: 'inset 0 -2px white, inset 0 -3px #CDCDCD',
    border: 0,
    background: 'none',
    outline: 'none',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      position: 'relative',
      top: '-10px',
    },
  },
  nameText: {
    fontSize: '2.8125rem',
    lineHeight: '1.15',
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    display: 'inline',
    padding: '0 2px',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      fontSize: '3.75rem',
      lineHeight: '1.17',
      minHeight: 52,
      textTransform: 'lowercase',
    },
  },
  nameTexthHide: {
    opacity: 0,
    position: 'relative',
    zIndex: -4,
  },
  btnEdit: {
    display: 'inline',
    cursor: 'pointer',
    width: 30,
    height: 30,
    borderRadius: '50%',
    background: COLORS.white,
    padding: 4,
    textAlign: 'center',
    fontSize: '1rem',
    border: 0,
    marginLeft: 5,
    marginTop: 5,
    outline: 'none',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: 52,
      height: 52,
      padding: 15,
      ['& img']: {
        width: '22px',
      },
    },
  },
  btnEditHide: {
    opacity: 0,
    position: 'relative',
    zIndex: -4,
  },
  btnIcon: {
    width: 16,
  },
  nameValidation: {
    position: 'absolute',
    color: COLORS.dangerRed,
    paddingTop: 15,
  },
  subBtns: {
    display: 'flex',
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  btnOther: {
    ['& > p']: {
      padding: '0 40px',
      width: '100%',
      justifyContent: 'center',
    },
    ['&:hover']: {
      ['& > img']: {
        filter: 'invert(100%)',
      },
    },
    [`@media (min-width: ${BREAKPOINTS.xmd}px)`]: {
      marginLeft: 20,
    },
  },
}))
