import { makeStyles } from '@material-ui/core/styles'
import { COLORS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  imagesContainer: {
    maxHeight: 800,
  },
  imageWrapper: {
    position: 'relative',
    padding: 4,
  },
  image: {
    objectFit: 'cover',
    width: 140,
    height: 140,
  },
  checkboxWrapper: {
    position: 'absolute',
    left: 18,
    top: 10,
    cursor: 'pointer',
    zIndex: 110,
  },
  whiteCircle: {
    position: 'absolute',
    cursor: 'pointer',
    width: 20,
    height: 20,
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  round: {
    position: 'relative',
    ['& label']: {
      backgroundColor: '#fff',
      borderRadius: '50%',
      cursor: 'pointer',
      height: 20,
      left: 0,
      position: 'absolute',
      top: 0,
      width: 20,
    },
    ['& label:after']: {
      borderTop: 'none',
      borderRight: 'none',
      content: '""',
      height: '6px',
      left: '7px',
      opacity: 0,
      position: 'absolute',
      top: '8px',
      width: '12px',
    },
    ['& input[type="checkbox"]']: {
      visibility: 'hidden',
    },
    ['& input[type="checkbox"]:checked + label']: {
      backgroundColor: '#F27870',
    },
    ['& input[type="checkbox"]:checked + label:after']: {
      opacity: 1,
    },
  },
  updateImageButton: {
    bottom: 10,
    right: 10,
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    color: COLORS.white,
    backgroundColor: COLORS.black,
    padding: '20px',
    zIndex: 10,
  },
}))
