import * as React from 'react'
import Switch from '@material-ui/core/Switch'
import { styles } from './styles'

const IOSSwitch = props => {
  const classes = styles()
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
}

export default IOSSwitch
