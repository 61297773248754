import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(theme => ({
  loading: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    background: 'rgba(255,255,255,0.5)',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  },
}))
