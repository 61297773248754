import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  wrapper: {
    display: 'block',
    width: '100%',
    paddingBottom: 5,
  },
  formControl: {
    width: '100%',
    minWidth: 120,
    maxWidth: 345,
    position: 'relative',
    borderBottom: `1px solid ${COLORS.black} !important`,
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.common.black} !important`,
      marginBottom: '-1px',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectList: {
    padding: 10,
    paddingLeft: 50,
    ['& > select']: {
      padding: 0,
      paddingLeft: 10,
      paddingRight: 0,
      backgroundColor: 'unset!important',
      fontFamily: FONTS.Schnyder.SchnyderXLLight,
      fontSize: '1.25rem',
      lineHeight: 'normal',
      ['& > option']: {
        fontFamily: FONTS.Schnyder.SchnyderXLLight,
      },
    },
  },
  sortByText: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '1.25rem',
    fontWeight: 400,
    position: 'absolute',
    bottom: 12,
    left: 0,
    margin: 0,
  },
  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    wrapper: {
      textAlign: 'right',
      padding: '42px 10px 0',
    },
    selectList: {
      paddingLeft: 75,
      ['& > select']: {
        fontSize: '1.875rem',
      },
    },
    sortByText: {
      fontSize: '1.875rem',
    },
  },
}))
