import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS } from '../../../constants'

export const styles = makeStyles(theme => ({
  modal: {
    outline: 'none',
    maxWidth: '100%',
    width: '100%',
    margin: '0 auto',
    color: theme.palette.common.black,
    ['& .ReactModal__Overlay']: {
      position: 'absolute',
    },
    [`@media (min-width: ${BREAKPOINTS.mdd}px)`]: {
      maxWidth: 420,
    },
  },
  modalContainer: {
    position: 'relative',
    outline: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: 18,
    right: 10,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    zIndex: 2,
    outline: 'none',
  },
  container: {
    padding: 20,
  },
  title: {
    margin: '20px 0',
    color: theme.palette.common.black,
  },
  shareBox: {
    margin: '0',
  },
  formShare: {
    margin: '0 0 20px',
  },
  inputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  convertedLink: {
    ['& > input']: {
      textAlign: 'left',
    },
  },
  input: {
    border: '2px solid #FAFBFD',
    background: '#FAFBFD',
    padding: '10px 20px',
    height: '55px',
    fontSize: '12px',
    textAlign: 'center',
    letterSpacing: '1.2px',
    borderRight: 0,
    marginBottom: 20,
  },
  errMsg: {
    color: COLORS.dangerRed,
    fontSize: 14,
    margin: 0,
  },
}))
