import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  profileWrapper: {
    backgroundColor: COLORS.whiteStain,
    padding: '35px 0',
    display: 'flex',
    flexWrap: 'wrap',
    // overflowX: 'hidden',
    '& p': {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 0,
    },
  },
  profileInner: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  personalInfo: {
    display: 'flex',

    width: '100%',
    marginBottom: 32,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      alignItems: 'start',
    },
  },
  profileActions: {
    width: '100%',
    justifyContent: 'flex-end',
    ['& button']: {
      margin: '5px 0 20px auto',
      [`@media (min-width: ${BREAKPOINTS.xlg}px)`]: {
        width: 'calc(33.33% - 10px)',
        margin: '0 5px',
      },
    },
    ['& button:last-child']: {
      marginBottom: 0,
    },
    [`@media (min-width: ${BREAKPOINTS.xlg}px)`]: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0 -10px',
      width: 'calc(50% + 20px)',
    },
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '50%',
      padding: '30px 0 5px 20px',
    },
  },
  sortOptionsWrapper: {
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      padding: 0,
    },
    '& > div': {
      [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
        padding: 0,
      },
    },
  },
  avatarCircle: {
    width: 100,
    height: 100,
    position: 'relative',
    marginTop: 0,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  rightInfo: {
    paddingLeft: '20px',
    marginTop: '-10px',
    width: 'calc(100% - 80px)',
  },
  userName: {
    marginBottom: 7,
    wordBreak: 'break-word',
    fontSize: '2.8rem !important',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      // marginTop: 25,
      lineHeight: '65px',
    },
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      marginBottom: '0px !important',
    },
  },
  profileBio: {
    fontSize: '1rem',
    lineHeight: '1.15',
    fontFamily: FONTS.Graphik.GraphikLight + ' !important',
    display: 'inline',
    padding: '0 2px',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      fontSize: '1rem !important',
      lineHeight: '1.17 !important',
      textTransform: 'lowercase',
    },
  },
  profileInput: {},
  profileEdit: {},
  editingBio: {
    position: 'relative',
    margin: '5px 0',
  },
  btn: {
    background: 'white',
    color: theme.palette.common.black,
    transition: '0.5s',
    maxWidth: 464,
    ['&:focus']: {
      outline: 'none',
    },
    ['& > p']: {
      color: theme.palette.common.black,
      marginLeft: '10px',
    },
    ['&:hover']: {
      color: theme.palette.common.white,
      background: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      ['& > p']: {
        color: theme.palette.common.white,
      },
    },
    ['& > img']: {
      width: '14px',
      height: 'auto',
      left: '19px',
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      maxWidth: '100%',
    },
  },
  btnOther: {
    ['& > p']: {
      padding: '0 20px',
      width: '100%',
      justifyContent: 'center',
    },
    ['&:hover']: {
      ['& > img']: {
        filter: 'invert(100%)',
      },
    },
  },
  btnIg: {
    background: 'white',
    color: theme.palette.common.black,
    transition: '0.5s',
    maxWidth: 464,
    ['&:focus']: {
      outline: 'none',
    },
    ['& > p']: {
      color: theme.palette.common.black,
    },
    ['&:hover']: {
      color: theme.palette.common.white,
      background: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      ['& > p']: {
        color: theme.palette.common.white,
      },
      ['& > img']: {
        filter: 'invert(100%)',
      },
    },
    ['& > img']: {
      width: '23px',
      height: 'auto',
      left: '19px',
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      maxWidth: '100%',
    },
  },
  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    profileWrapper: {
      marginBottom: 40,
      paddingTop: 10,
      paddingBottom: 42,
    },
    personalInfo: {
      width: '50%',
      marginBottom: 0,
      paddingTop: 5,
    },
    userName: {
      fontSize: '5rem !important',
      lineHeight: '105px !important',
      [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
        fontSize: '3.75rem !important',
        lineHeight: '85px !important',
      },
    },
    avatarCircle: {
      width: 100,
      height: 100,
      minWidth: 100,
      marginTop: 25,
    },
  },
  btnEdit: {
    display: 'inline',
    cursor: 'pointer',
    background: COLORS.white,
    border: 'none',
    outline: 'none',
    marginLeft: 15,
    borderRadius: 999,
  },
  btnEditHide: {
    opacity: 0,
    position: 'relative',
    zIndex: -4,
  },
  btnIcon: {
    width: 16,
  },
  shortLabel: {
    fontSize: '0.875rem',
    color: '#CDCDCD',
    padding: '6px 0',
    boxShadow: 'inset 0 -2px white, inset 0 -3px #CDCDCD',
    border: 0,
    background: 'none',
    outline: 'none',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      position: 'relative',
      top: 10,
    },
  },
  shortText: {
    fontSize: '1rem',
    lineHeight: '1.15',
    fontFamily: FONTS.Graphik.GraphikLight,
    display: 'inline',
    padding: '0 2px',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      fontSize: '1rem',
      lineHeight: '1.17',
      minHeight: 52,
      textTransform: 'lowercase',
    },
  },
  shortTexthHide: {
    opacity: 0,
    position: 'relative',
    zIndex: -4,
  },
  shortBioInput: {
    width: '100%',
    display: 'inline',
    padding: '5px 0',
    fontSize: '1rem',
    lineHeight: '1.15',
    fontFamily: FONTS.Graphik.GraphikLight,
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0,
    zIndex: -1,
    letterSpacing: 0,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      fontSize: '1rem',
      lineHeight: '1.17',
      textTransform: 'lowercase',
    },
  },
  shortBioInputShow: {
    zIndex: 0,
    opacity: 10,
  },
  loading: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    background: 'rgba(255,255,255,0.5)',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  },
  messageError: {
    background: '#ff867e',
    padding: '10px 0',
    color: '#fff',
    ['& p']: {
      margin: 0,
    },
  },
  blogLink: {
    color: COLORS.black,
    fontSize: 16,
    marginTop: -10,
  },
  blogLinkInput: {
    fontSize: 16,
    marginTop: 0,
    textDecoration: 'underline',
  },
  shareBtnWraapper: {
    border: '2px solid #000',
    borderRadius: 50,
    backgroundColor: COLORS.white,
    width: 54,
    height: 54,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    ['&:hover']: {
      color: theme.palette.common.white,
      background: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      ['& > img']: {
        filter: 'invert(100%)',
      },
    },
  },
  breadcrumb: {
    [`& nav.MuiBreadcrumbs-root`]: {
      [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
        marginBottom: 20,
      },
    },
    [`& li.MuiBreadcrumbs-li a`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      color: COLORS.black,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      cursor: 'pointer',
      fontFamily: 'Graphik Light',
    },
    [`& li.MuiBreadcrumbs-separator`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
      marginTop: 5,
    },
    [`& li.MuiBreadcrumbs-li p`]: {
      marginTop: 5,
      height: 27.43,
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '31px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
      [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
        marginTop: 6,
      },
    },
  },
}))
