import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  seoHeading: {
    fontSize: '14px !important',
    fontFamily: 'Graphik Light !important',
    letterSpacing: '0.00938em !important',
    lineHeight: '1.64 !important',
    marginBottom: '0px !important',
    fontWeight: 400,
    color: COLORS.lightGrey,
  },
  wrapperPersonal: {
    padding: '20px 0 100px',
    maxWidth: '90%',
    margin: '0 auto',
    textAlign: 'center',
    ['& p']: {
      color: '#ABABAB',
      fontFamily: FONTS.Graphik.GraphikLight,
      fontSize: '0.875rem',
      lineHeight: '1.64',
    },
  },
  wrapper: {
    maxWidth: '90%',
    margin: '0 auto',
  },
  mediaContainer: {},
  media: {
    margin: '0 -7% 40px',
  },
  titleWrapper: {
    margin: '0 0 37px',
  },
  title: {
    fontSize: '2.25rem',
    lineHeight: '1.14',
  },
  subTitle: {
    fontSize: '1.875rem',
    margin: '0 0 20px',
  },
  infoContainer: {},
  infoTop: {
    ['& p']: {
      fontFamily: FONTS.Graphik.GraphikLight,
      fontSize: '1rem',
      lineHeight: '1.44',
      margin: '0 0 23px',
    },
  },
  actions: {
    textAlign: 'right',
    paddingTop: 8,
    ['& button']: {
      float: 'right',
    },
  },
  [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
    wrapperPersonal: {
      maxWidth: '100%',
    },
    wrapper: {
      maxWidth: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    mediaContainer: {
      width: '66%',
    },
    media: {
      margin: '0 0 40px',
    },
    titleWrapper: {
      margin: '0 0 17px',
      width: '100%',
    },
    title: {
      fontSize: '3.75rem',
      lineHeight: '1.83',
      paddingLeft: '124px',
    },
    subTitle: {
      fontSize: '2.5rem',
      lineHeight: '1.125',
    },
    infoContainer: {
      width: '34%',
      paddingLeft: '20px',
    },
    actions: {
      paddingTop: 50,
    },
  },
}))
