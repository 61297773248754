import React from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Typography from '@material-ui/core/Typography'
import { styles } from './styles'
import { navigate } from 'gatsby'
import Img from 'gatsby-image'
import CHRArrowButton from '../../arrowButton'
import { ROUTES } from '../../../constants'
import ReactHtmlParser from 'react-html-parser'

interface BecomeInfluencerProps {
  image: any
  shoppingUserProfile: any
  t: TFunction
}

const BecomeInfluencer = ({
  image,
  shoppingUserProfile,
  t,
}: BecomeInfluencerProps) => {
  const classes = styles()

  const renderImg = (image: any, isGatsbyImg: boolean) => {
    return (
      <Img
        fluid={image}
        alt={t('imageAlts.placeholder')}
        className={`${classes.media}`}
      />
    )
  }

  return (
    <>
      <div className={classes.wrapperPersonal}>
        <Typography component="p">
          {ReactHtmlParser(t('shoppingBoard.personalShoppingBoardText'))}
        </Typography>
        <Typography component="h1" variant="h1" className={classes.seoHeading}>
          {t('seo.users.h1').replace(
            '[Username]',
            shoppingUserProfile.username
          )}
        </Typography>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.titleWrapper}>
          <Typography variant="h2" className={classes.title}>
            {ReactHtmlParser(t('shoppingBoard.becomeInfluencer'))}
          </Typography>
        </div>
        <div className={classes.mediaContainer}>{renderImg(image, true)}</div>
        <article className={classes.infoContainer}>
          <div className={classes.infoTop}>
            {' '}
            <Typography variant="h3" className={classes.subTitle}>
              {ReactHtmlParser(t('shoppingBoard.makeYourTaste0'))}
            </Typography>
            <Typography component="p">
              {ReactHtmlParser(t('shoppingBoard.makeYourTaste1'))}
            </Typography>
            <Typography component="p">
              {ReactHtmlParser(t('shoppingBoard.makeYourTaste2'))}
            </Typography>
            <Typography component="p">
              {ReactHtmlParser(t('shoppingBoard.makeYourTaste3'))}
            </Typography>
          </div>
          <div className={classes.actions}>
            <CHRArrowButton
              label={t('shoppingBoard.join')}
              onClick={() => navigate(ROUTES.join)}
            />
          </div>
        </article>
      </div>
    </>
  )
}
export default withTranslation()(BecomeInfluencer)
