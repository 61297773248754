import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  wrapper: {
    paddingTop: 0,
    marginTop: '-13px',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      paddingTop: '40px',
      marginTop: 0,
    },
  },
}))
