import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
// import { useParams } from '@reach/router'
import Layout from '../../layouts/defaultLayout'
import CHRSectionContainer from '../sectionContainer'
import SEO from '../seo'
import { styles } from './styles'
import Listing from './listing'

interface FolderPageProps {
  t: TFunction
}

const FolderPage = ({ t }: FolderPageProps) => {
  const classes = styles()

  return (
    <>
      <Layout>
        <SEO
          title="shopping board folder"
          description={t('shoppingBoard.metaDescription')}
        />
        <CHRSectionContainer customStyle={classes.wrapper}>
          <Listing />
        </CHRSectionContainer>
      </Layout>
    </>
  )
}

export default withTranslation()(FolderPage)
