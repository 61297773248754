import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(theme => ({
  round: {
    position: 'relative',
    ['& label']: {
      backgroundColor: '#fff',
      borderRadius: '50%',
      cursor: 'pointer',
      height: 46,
      left: 0,
      position: 'absolute',
      top: 0,
      width: 46,
    },
    ['& label:after']: {
      borderTop: 'none',
      borderRight: 'none',
      content: '""',
      height: '6px',
      left: '7px',
      opacity: 0,
      position: 'absolute',
      top: '8px',
      width: '12px',
    },
    ['& input[type="checkbox"]']: {
      visibility: 'hidden',
    },
    ['& input[type="checkbox"]:checked + label']: {
      backgroundColor: '#F27870',
    },
    ['& input[type="checkbox"]:checked + label:after']: {
      opacity: 1,
    },
  },
}))
