import React from 'react'
import { usePreview } from 'react-dnd-preview'
import CHRCard from '../shoppingBoard/gridItemsStatic/card'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const MyPreview = () => {
  const { display, itemType, item, style } = usePreview()
  const theme = useTheme()
  const isTouch = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })

  if (!display || !isTouch) {
    return null
  }
  return (
    <div className="item-list__item" style={style}>
      <CHRCard {...item.props} />
    </div>
  )
}

export default MyPreview
