import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, FONTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  container: {
    width: '100%',
    overflow: 'hidden',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
    },
  },
  perItemsContainer: {
    marginBottom: 50,
  },
  perItems: {
    display: 'none',
    fontFamily: FONTS.Graphik.GraphikRegular + ' !important',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontSize: '1rem',
    marginBottom: '42px !important',
    lineHeight: '1.125',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'block',
      marginTop: 14,
    },
  },
  summaryItems: {},
  container404: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 960,
    margin: '0px auto',
  },
  logo: {
    display: 'block',
    marginBottom: 40,
  },
  button404: {
    maxWidth: 460,
  },
  btn: {
    background: 'none',
    color: theme.palette.common.black,
    transition: '0.5s',
    minWidth: 250,
    maxWidth: 350,
    width: '45%',
    ['&:focus']: {
      outline: 'none',
    },
    ['& > p']: {
      color: theme.palette.common.black,
    },
    ['&:hover']: {
      color: theme.palette.common.white,
      background: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      ['& > p']: {
        color: theme.palette.common.white,
      },
    },
    ['& > img']: {
      width: '14px',
      height: 'auto',
      left: '19px',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      width: '45%',
      marginBottom: 15,
      maxWidth: '100%',
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  btnOther: {
    marginLeft: 20,
    ['& > p']: {
      padding: '0 40px',
      width: '100%',
      justifyContent: 'center',
    },
    ['&:hover']: {
      ['& > img']: {
        filter: 'invert(100%)',
      },
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      marginLeft: 0,
    },
  },
  subBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: 600,
    [`@media (max-width: ${BREAKPOINTS.xml}px)`]: {
      justifyContent: 'center',
      maxWidth: 600,
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      justifyContent: 'center',
      maxWidth: '100%',
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      flexDirection: 'column',
      width: '100%',
      maxWidth: '100%',
    },
  },
  bottomMargin: {
    marginBottom: 20,
  },
  subGrid: {
    // marginBottom: 50,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      marginBottom: 0,
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 0,
    },
  },
  switchBtn: {
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      marginLeft: 20,
    },
  },
}))
