import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  dialogContainer: {
    fontFamily: FONTS.Graphik.GraphikRegular,
    padding: 20,
    maxWidth: 450,
    margin: '0 auto',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      margin: '0 auto',
      width: '85%',
    },
  },
  closeButton: {
    position: 'absolute',
    bottom: 40,
    right: 0,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  dialogTitleSection: {
    position: 'relative',
    padding: 0,
    marginTop: 30,
    marginBottom: 30,
    ['& > h2']: {
      textAlign: 'center',
    },
  },
}))
