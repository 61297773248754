import React, { useRef, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import closeMenuIcon from '../../../assets/images/close.svg'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Typography from '@material-ui/core/Typography'
import CHRButton from '../../button'
import CHRInput from '../../input'

interface SharePrivateFolderdModalProps {
  isPublic: boolean
  handleClose: any
  openStatus: boolean
  shareUrl: string
  error: string
  t: TFunction
}

const SharePrivateFolderModal = ({
  isPublic,
  handleClose,
  openStatus,
  shareUrl,
  error,
  t,
}: SharePrivateFolderdModalProps) => {
  const classes = styles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const textAreaRef = useRef(null)
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = (event: any) => {
    setIsCopied(true)
    if (textAreaRef && textAreaRef?.current !== null) {
      textAreaRef?.current?.select()
      document.execCommand('copy')
      event?.target?.focus()
    }
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openStatus}
        onClose={handleClose}
        aria-labelledby="shareShoppingBoard"
        className={classes.modal}
      >
        <section className={classes.modalContainer}>
          <button onClick={handleClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>
          {(shareUrl || error) && (
            <div className={classes.container}>
              <Typography variant="h2" component="h2" className={classes.title}>
                share your {`${isPublic ? '' : 'private'}`} folder using this
                link
              </Typography>
              <div className={classes.shareBox}>
                <div className={classes.formShare}>
                  <div
                    className={`${classes.inputContainer} ${classes.convertedLink}`}
                  >
                    {shareUrl && (
                      <CHRInput
                        placeholder=""
                        value={shareUrl}
                        onChange={() => shareUrl}
                        elementRef={textAreaRef}
                        customStyles={classes.input}
                      />
                    )}
                    {error ? (
                      <p className={classes.customErrorMsg}>{error}</p>
                    ) : (
                      document.queryCommandSupported('copy') && (
                        <CHRButton
                          label={
                            !isCopied
                              ? t('shoppingBoard.copyBtn')
                              : t('messages.copiedToClipboard')
                          }
                          onClick={copyToClipboard}
                          customStyle={classes.btnCopy}
                          hoverEffects={true}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </Dialog>
    </>
  )
}

export default withTranslation()(SharePrivateFolderModal)
