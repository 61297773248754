import React, { useState, useEffect } from 'react'
import { Router } from '@reach/router'
import ShoppingBoardPage from '../components/shoppingBoard'
import FolderPage from '../components/folder'
import NotFoundPage from './404'
import { graphql } from 'gatsby'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LoadingScreen from '../components/loadingScreen'

function getDropTargetElementsAtPoint(x, y, dropTargets) {
  return dropTargets.filter(t => {
    const rect = t.getBoundingClientRect()
    return (
      x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
    )
  })
}

const ShoppingBoard = props => {
  const [backendOptions, setBackendOptions] = useState(null)
  const theme = useTheme()
  const isTouch = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })

  useEffect(() => {
    if (isTouch) {
      const hasNative =
        document && (document.elementsFromPoint || document.msElementsFromPoint)
      const options = {
        getDropTargetElementsAtPoint:
          !hasNative && getDropTargetElementsAtPoint,
        delayTouchStart: 100,
      }
      setBackendOptions(options)
    }
  }, [isTouch])

  return (
    <>
      {isTouch ? (
        backendOptions ? (
          <DndProvider backend={TouchBackend} options={backendOptions}>
            <Router basepath="/s">
              <ShoppingBoardPage path=":userName" data={props.data} />
              <FolderPage path=":userName/:folderName/:folderId" />
              <NotFoundPage default withLayout={false} />
            </Router>
          </DndProvider>
        ) : (
          <LoadingScreen />
        )
      ) : (
        <DndProvider backend={HTML5Backend}>
          <Router basepath="/s">
            <ShoppingBoardPage path=":userName" data={props.data} />
            <FolderPage path=":userName/:folderName/:folderId" />
            <NotFoundPage default withLayout={false} />
          </Router>
        </DndProvider>
      )}
    </>
  )
}

export default ShoppingBoard

export const query = graphql`
  query {
    shoppingBoardBanner: file(
      relativePath: { eq: "shoppingBoard/banner-1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    metaImage: file(relativePath: { eq: "shoppingBoard/social-share.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
