import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS } from '../../../constants'

export const styles = makeStyles(theme => ({
  gridItem: {
    position: 'relative',
    cursor: 'pointer',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  image: {
    width: '33%',
    minHeight: 232,
    objectFit: 'cover',
    height: 232,
  },
  deleteButton: {
    position: 'absolute',
    left: 18,
    top: 10,
    cursor: 'pointer',
    width: 30,
    height: 30,
    borderRadius: '50%',
    background: '#fff',
    padding: 4,
    textAlign: 'center',
    zIndex: 110,
    userSelect: 'none',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: 40,
      height: 40,
      padding: 11,
    },
  },
  deleteIcon: {
    width: 10,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: 14,
    },
  },
  switchBtns: {
    marginBottom: 40,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  description: {
    color: COLORS.lightGrey,
    marginBottom: 5,
    marginTop: 20,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  folderName: {
    marginTop: 20,
  },
  mainGrid: {
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0,
      },
    },
  },
  edgeSpaces: {
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
}))
