import React from 'react'
import { styles } from './style'

const SBcheckbox = ({
  checked,
  onChange,
  id,
}: {
  checked: boolean
  onChange: Function
  id: string
}) => {
  const classes = styles()
  return (
    <div className={classes.round}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={() => onChange(id)}
      />
      <label htmlFor={id}></label>
    </div>
  )
}

export default SBcheckbox
