import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS, COLORS } from '../../../constants'

export const styles = makeStyles(theme => ({
  dialogContainer: {
    fontFamily: FONTS.Graphik.GraphikRegular,
    padding: 20,
    maxWidth: 450,
    margin: '0 auto',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      margin: '0 auto',
      width: '85%',
    },
  },

  closeButton: {
    position: 'absolute',
    bottom: 40,
    right: 0,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },

  dialogTitleSection: {
    position: 'relative',
    padding: 0,
    marginTop: 60,
    marginBottom: 60,
    ['& > h2']: {
      textAlign: 'center',
    },
  },

  container: {
    display: 'flex',
    backgroundColor: COLORS.whiteStain,
    margin: '20px 0',
    width: '100%',
    '& img': {
      width: 30,
      height: 30,
    },
    '& p': {
      margin: 0,
      padding: 17,
      color: COLORS.lightGrey,
      fontFamily: FONTS.Graphik.GraphikLight,
      fontSize: '0.75rem',
    },
  },
  imgBox: {
    backgroundColor: COLORS.ashGrey,
    width: 54,
    height: 54,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabledButton: {
    fontFamily: FONTS.Graphik.GraphikLight,
    backgroundColor: COLORS.ashGrey,
    color: theme.palette.common.white,
    textAlign: 'center',
    padding: 19,
    fontSize: '1rem',
    height: 54,
    marginBottom: 40,
    width: '100%',
    border: 'none',
    cursor: 'pointer',
  },
  socialInput: {
    width: '100%',
    border: 'none',
    padding: '0 20px',
    fontSize: '1rem',
  },
  connectError: {
    color: '#FF3860',
    fontSize: '0.85rem',
    margin: '-10px 0 20px',
  },
}))
