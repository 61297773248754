import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { styles } from './style'

interface ChooseImageProps {
  images: any
  currentImage: string
  setSelectedImage: Function
}

const ChooseImage = ({
  images,
  currentImage,
  setSelectedImage,
}: ChooseImageProps) => {
  const [appliedImages, setAppliedImages] = useState([])
  const [finished, setFinished] = useState(false)
  const classes = styles()
  const [checkedImage, setCheckedImage] = useState('')

  useEffect(() => {
    filterImages()
  }, [images])

  useEffect(() => {
    if (finished) {
      appliedImages.forEach((image: string, index: number) => {
        if (image.includes(currentImage)) {
          setCheckedImage(image)
        }
      })
    }
  }, [finished, appliedImages])

  const filterImages = async () => {
    images.forEach((imgSrc: string, index: number) => {
      const img = new Image()
      img.src = imgSrc as string
      img.onload = function() {
        if (img.width > 100 && img.height > 100) {
          setAppliedImages((prev: any) => [...prev, imgSrc])
        }
      }
    })
    setFinished(true)
  }

  const onCheckImage = (e: any, img: string) => {
    e.preventDefault()
    setCheckedImage(img)
    setSelectedImage(img)
  }

  // const handleUpdateImage = async (e: any) => {
  //   e.stopPropagation()
  //   handleUpdate(checkedImage)
  // }

  return (
    <div>
      {!finished ? (
        <CircularProgress size={40} />
      ) : appliedImages.length > 0 ? (
        <div>
          <Grid container className={classes.imagesContainer} spacing={1}>
            {appliedImages.slice(0, 16).map((img: string) => {
              return (
                <Grid item lg={3} md={4} sm={12} xs={12}>
                  <div
                    className={classes.imageWrapper}
                    onClick={e => onCheckImage(e, img)}
                  >
                    <img
                      src={img}
                      key={img}
                      className={classes.image}
                      alt="choose-image"
                    />
                    <div className={classes.checkboxWrapper}>
                      <div className={classes.whiteCircle}></div>
                      <div className={classes.round}>
                        <input
                          type="checkbox"
                          id={img}
                          checked={checkedImage === img}
                          onChange={e => onCheckImage(e, img)}
                        />
                        <label htmlFor={img}></label>
                      </div>
                    </div>
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </div>
      ) : (
        <div>No Image Found</div>
      )}
    </div>
  )
}

export default ChooseImage
