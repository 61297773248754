import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  wrapper: {
    maxWidth: '90%',
    margin: '0 auto',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      maxWidth: '1187px',
    },
  },
  text: {
    margin: '0 0 63px',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      marginBottom: 20,
    },
  },
  btnAsLink: {
    background: 'none',
    border: 0,
    width: 'auto',
    display: 'inline-block',
    padding: 0,
    height: 'auto',
    margin: 0,
    textAlign: 'left',
    wordBreak: 'break-all',
    ['&:hover']: {
      background: 'none',
      color: theme.palette.common.black,
      ['& > p']: {
        color: theme.palette.common.black,
      },
    },
    ['&:focus']: {
      border: 0,
      outline: 'none',
    },
    ['& > p']: {
      fontFamily: 'Schnyder XL Light',
      fontSize: '2.25rem' /*equals 36px*/,
      lineHeight: '40px',
      textTransform: 'lowercase',
      boxShadow: `inset 0 -2px white, inset 0 -3px ${theme.palette.common.black}`,
      display: 'inline',
      color: theme.palette.common.black,
      [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
        fontSize: '3.75rem' /*equals 60px*/,
        lineHeight: '86px',
        boxShadow: `inset 0 0 white, inset 0 -1px ${theme.palette.common.black}`,
      },
    },
  },
  showHide: {
    display: 'none',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'inline-block',
    },
  },
  btnWrapper: {
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'block',
      marginBottom: 87,
    },
  },
  btn: {
    background: 'none',
    color: theme.palette.common.black,
    transition: '0.5s',
    maxWidth: 464,
    ['&:focus']: {
      border: 0,
      outline: 'none',
    },
    ['& > p']: {
      color: theme.palette.common.black,
    },
    ['&:hover']: {
      color: theme.palette.common.white,
      background: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      ['& > p']: {
        color: theme.palette.common.white,
      },
    },
  },
  emptyContainer: {
    maxWidth: 726,
    width: '100%',
    margin: '20px auto',
  },
  emptyTitle: {
    marginBottom: 20,
  },
  customLink: {
    textDecoration: 'none',
    borderBottom: '1px solid #000',
    color: COLORS.black,
    cursor: 'pointer',
  },
}))
