import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  modalRoot: {},
  modal: {
    outline: 'none',
    maxWidth: '100%',
    width: '100%',
    margin: '0 auto',
    color: theme.palette.common.black,
    ['& .ReactModal__Overlay']: {
      position: 'absolute',
    },
    [`@media (min-width: ${BREAKPOINTS.mdd}px)`]: {
      maxWidth: 420,
    },
  },
  modalContainer: {
    position: 'relative',
    outline: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: 18,
    right: 10,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    zIndex: 2,
    outline: 'none',
  },
  container: {
    padding: 20,
  },
  shareBox: {
    margin: '0',
  },
  formShare: {
    margin: '0 0 20px',
  },
  inputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  input: {
    border: '2px solid #FAFBFD',
    background: '#FAFBFD',
    padding: '10px 20px',
    height: '55px',
    fontSize: '12px',
    textAlign: 'center',
    letterSpacing: '1.2px',
    borderRight: 0,
    marginBottom: 20,
  },
  btnCopy: {
    width: '100%',
    margin: 0,
    outline: 'none',
  },
  convertedLink: {
    ['& > input']: {
      textAlign: 'left',
    },
  },
  shopLinkWrap: {},
  viaSocials: {},
  viaShort: {
    textAlign: 'center',
    fontSize: '1rem',
    fontFamily: FONTS.Graphik.GraphikLight,
    lineHeight: '1.69',
    marginBottom: 15,
    color: theme.palette.common.black,
  },
  viaLinks: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  socialButton: {
    width: '100%',
    margin: '0 0 20px',
    [' & > button']: {
      display: 'flex',
      border: '2px solid #000!important',
      overflow: 'hidden',
      padding: '14px 20px!important',
      outline: 'none',
      background: '#fff',
      cursor: 'pointer',
      width: '100%',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      height: '54px',
    },
    [' & img']: {
      maxWidth: '100%',
      float: 'left',
      width: '23px',
      position: 'absolute',
      left: '20px',
      top: '14px',
    },
  },
  secondBtn: {
    ['& .icon-default']: {},
    ['& .icon-hover']: {
      display: 'none',
    },
    ['&:hover']: {
      color: `${theme.palette.common.white} !important`,
      background: `${theme.palette.common.black} !important`,
      borderColor: theme.palette.common.black,
      ['& > p']: {
        color: theme.palette.common.white,
      },
      ['& span']: {
        color: theme.palette.common.white,
      },
      ['& .icon-default']: {
        display: 'none!important',
      },
      ['& .icon-hover']: {
        display: 'inline-block!important',
      },
    },
  },
  socialIcon: {},
  smsButton: {},
  socialText: {
    fontSize: '0.75rem',
    fontFamily: FONTS.Graphik.GraphikLight,
    lineHeight: '1.16',
    color: theme.palette.common.black,
    letterSpacing: '1.2px',
  },
  des: {
    fontSize: '1rem',
    fontFamily: FONTS.Graphik.GraphikLight,
    lineHeight: '1.43',
    marginBottom: 20,
    color: theme.palette.common.black,
  },
  title: {
    margin: '20px 0',
  },
}))
