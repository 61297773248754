import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS, COLORS } from '../../../constants'

export const styles = makeStyles(theme => ({
  modalRoot: {},
  modal: {
    outline: 'none',
    maxWidth: '100%',
    width: '100%',
    margin: '0 auto',
    color: theme.palette.common.black,
    ['& .ReactModal__Overlay']: {
      position: 'absolute',
    },
    [`@media (min-width: ${BREAKPOINTS.mdd}px)`]: {
      maxWidth: 440,
    },
  },
  modalContainer: {
    position: 'relative',
    outline: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 13,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    zIndex: 2,
    outline: 'none',
  },
  container: {
    padding: 20,
  },
  shareBox: {
    margin: 0,
  },
  formShare: {
    margin: 0,
  },
  inputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    border: '2px solid #FAFBFD',
    background: '#FAFBFD',
    padding: '10px 20px',
    height: '55px',
    fontSize: '12px',
    textAlign: 'center',
    letterSpacing: '1.2px',
    borderRight: 0,
    marginBottom: 0,
    ['&::-webkit-input-placeholder']: {
      color: 'black',
    },
    ['&::-moz-placeholder']: {
      color: 'black',
    },
    ['&:-ms-input-placeholder']: {
      color: 'black',
    },
    ['&:-moz-placeholder']: {
      color: 'black',
    },
  },
  btnCopy: {
    width: '100%',
    outline: 'none',
    margin: '0 0 10px',
  },
  convertedLink: {
    ['& > input']: {
      textAlign: 'left',
    },
  },
  shopLinkWrap: {},
  viaSocials: {},
  viaShort: {
    textAlign: 'center',
    fontSize: '1rem',
    fontFamily: FONTS.Graphik.GraphikLight,
    lineHeight: '1.69',
    marginBottom: 15,
    color: theme.palette.common.black,
  },
  viaLinks: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  socialButton: {
    width: '100%',
    margin: '0 0 20px',
    [' & > button']: {
      display: 'flex',
      border: '2px solid #000!important',
      overflow: 'hidden',
      padding: '14px 20px!important',
      outline: 'none',
      background: '#fff',
      cursor: 'pointer',
      width: '100%',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      height: '54px',
    },
    [' & img']: {
      maxWidth: '100%',
      float: 'left',
      width: '23px',
      position: 'absolute',
      left: '20px',
      top: '14px',
    },
  },
  secondBtn: {
    ['& .icon-default']: {},
    ['& .icon-hover']: {
      display: 'none',
    },
    ['&:hover']: {
      color: `${theme.palette.common.white} !important`,
      background: `${theme.palette.common.black} !important`,
      borderColor: theme.palette.common.black,
      ['& > p']: {
        color: theme.palette.common.white,
      },
      ['& span']: {
        color: theme.palette.common.white,
      },
      ['& .icon-default']: {
        display: 'none!important',
      },
      ['& .icon-hover']: {
        display: 'inline-block!important',
      },
    },
  },
  socialIcon: {},
  smsButton: {},
  socialText: {
    fontSize: '0.75rem',
    fontFamily: FONTS.Graphik.GraphikLight,
    lineHeight: '1.16',
    color: theme.palette.common.black,
    letterSpacing: '1.2px',
  },
  des: {
    fontSize: '1rem',
    fontFamily: FONTS.Graphik.GraphikLight,
    lineHeight: '1.43',
    marginBottom: 20,
    color: theme.palette.common.black,
  },
  title: {
    margin: '10px 0 15px',
    lineHeight: 1.1,
  },
  gridWrapper: {
    marginBottom: '20px',
    position: 'relative',
    minHeight: 415,
  },
  gridItems: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: '0 -2.5px',
    padding: '0',
    maxHeight: '415px',
    overflowY: 'auto',
  },
  gridItem: {
    width: '33.33%',
    padding: '2.5px',
    position: 'relative',
    ['& .dotted']: {
      background: '#F27870',
      border: '2px solid #fff',
    },
    // ['&:hover .dot']: {
    //   background: '#F27870',
    //   border: '2px solid #fff'
    // },
    // ['&:hover .dotted']: {
    //   background: '#fff',
    //   border: '2px solid #fff'
    // }
  },
  cardMedia: {
    fontSize: 0,
    cursor: 'pointer',
  },
  line: {
    display: 'block',
    width: '100%',
    borderBottom: '1px solid #000',
    position: 'absolute',
    bottom: '0',
    left: 0,
  },
  dot: {
    display: 'block',
    width: 20,
    height: 20,
    background: '#fff',
    position: 'absolute',
    top: '9px',
    left: '7px',
    zIndex: 1,
    borderRadius: '50%',
    // ['&:hover']: {
    //   background: '#F27870',
    //   border: '2px solid #fff'
    // }
  },
  dotted: {
    background: '#F27870',
    border: '2px solid #fff',
  },
  loading: {
    position: 'absolute',
    zIndex: 1000,
    background: 'rgba(255,255,255,0.5)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0,
  },
  message: {
    color: 'red',
    margin: '0 0 20px',
    fontSize: '1rem',
  },
}))
