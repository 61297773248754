import React, { useState, useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { styles } from './styles'
import CardMedia from '@material-ui/core/CardMedia'
import { COLORS } from '../../../constants'
import defaultImage from './../../../assets/images/thumbnail-default.png'
import { CircularProgress } from '@material-ui/core'
import _ from 'lodash'

interface BoardProfileProps {
  t: TFunction
  item: any
  indexItem: number
  handleLoadedImage: any
}

const InstagramMedia = ({ t, item }: BoardProfileProps) => {
  const classes = styles()

  const [loading, setLoading] = useState(true)
  const [currentImage, setCurrentImage] = useState(defaultImage)

  useEffect(() => {
    const imgElement = new Image()

    imgElement.src = item.media_url

    imgElement.onload = () => {
      setCurrentImage(item.media_url)
      setLoading(false)
    }

    imgElement.onerror = err => {
      // console.log({ err })
    }
  }, [item])

  return (
    <>
      <div className={classes.wrapper}>
        {loading && (
          <div className={classes.loading}>
            <CircularProgress style={{ color: COLORS.black }} size={20} />
          </div>
        )}
        <CardMedia
          component="img"
          alt={''}
          image={currentImage}
          title={''}
          className={classes.cardMedia}
        />
      </div>
    </>
  )
}
export default withTranslation()(InstagramMedia)
