import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { usePagination } from '@material-ui/lab/Pagination'
import arrowNext from '../../../assets/images/arrow-back.svg'
import arrowPrev from '../../../assets/images/arrow-left.svg'
import CHRButton from '../../button'
import { styles } from './styles'

interface GridPagingProps {
  t: TFunction
  pages: number
  selected?: number
  onChange: any
  handleLoadMore: any
}

const GridPaging = ({
  t,
  pages,
  selected = 1,
  onChange,
  handleLoadMore,
}: GridPagingProps) => {
  const classes = styles()

  let { items } = usePagination({
    count: pages,
    onChange: onChange,
  })

  return (
    <div className={classes.wrapper}>
      <ul className={classes.ul}>
        <li className="previous">
          <button
            type="button"
            className={`${classes.btn} ${classes.btnPrev}`}
            disabled={selected === 1 ? true : false}
            onClick={() => onChange(+selected - 1)}
          >
            <span className={`icon ${classes.btnIconPrev}`}>
              <img src={arrowPrev} alt="arrow icon" />
            </span>
            <span>previous</span>
          </button>
        </li>
        <li className="page">
          <p type="item" aria-current="true" className={classes.item}>
            {selected} / {pages}
          </p>
        </li>
        <li className="next">
          <button
            type="button"
            className={`${classes.btn} ${classes.btnNext}`}
            disabled={selected === pages ? true : false}
            onClick={() => onChange(+selected + 1)}
          >
            <span>next</span>
            <span className={`icon ${classes.btnIconNext}`}>
              <img src={arrowNext} alt="arrow icon" />
            </span>
          </button>
        </li>
      </ul>
      {selected < pages && (
        <CHRButton
          label={t('shoppingBoard.loadMore')}
          onClick={() => handleLoadMore(+selected + 1)}
          customStyle={classes.btnLoadMore}
          hoverEffects={true}
          disabled={selected === pages ? true : false}
        />
      )}
    </div>
  )
}
export default withTranslation()(GridPaging)
