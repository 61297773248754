import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, FONTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  wrapper: {
    margin: '40px 0',
    padding: '0 10px',
    textAlign: 'center',
  },
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'none',
    ['& .previous']: {
      float: 'left',
      marginRight: 20,
    },
    ['& .page']: {
      display: 'inline-block',
    },
    ['& .next']: {
      float: 'right',
      marginLeft: 20,
    },
  },
  item: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '1.875rem',
    lineHeight: '1.13',
  },
  btn: {
    background: 'none',
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    border: 0,
    padding: '30px 0',
    margin: 0,
    fontSize: '1.875rem',
    lineHeight: '1.13',
    outline: 'none',
    cursor: 'pointer',
    ['&:disabled .icon']: {
      opacity: '0.3',
    },
  },
  btnPrev: {},
  btnNext: {},
  btnIconNext: {
    marginLeft: 20,
    outline: 'none',
  },
  btnIconPrev: {
    marginRight: 20,
    outline: 'none',
  },
  btnLoadMore: {
    background: 'none',
    color: theme.palette.common.black,
    transition: '0.5s',
    maxWidth: 464,
    outline: 'none',
    ['&:focus']: {
      outline: 'none',
    },
    ['& > p']: {
      color: theme.palette.common.black,
    },
    ['&:hover']: {
      color: theme.palette.common.white,
      background: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      ['& > p']: {
        color: theme.palette.common.white,
      },
    },
    ['&:disabled']: {
      border: '2px solid #000',
      opacity: '0.5',
    },
  },
  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    wrapper: {
      margin: '0 0 10px',
      padding: '0',
    },
    btnLoadMore: {
      display: 'none',
    },
    ul: {
      display: 'block',
    },
  },
}))
