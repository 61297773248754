import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import closeMenuIcon from './../../../assets/images/close.svg'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { socialMedia, regExps, ENDPOINTS } from '../../../constants'
import axios from '../../../axios'

interface ConnectInstagramModalProps {
  handleClose: any
  openStatus: boolean
  socialUrls: any
  userId: any
  handleAfterConnect: Function
  t: TFunction
}

const ConnectInstagramModal = ({
  handleClose,
  openStatus,
  socialUrls,
  userId,
  handleAfterConnect,
  t,
}: ConnectInstagramModalProps) => {
  const classes = styles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState('')
  const [error, setError] = useState('')

  const handleChangeSocialStatus = async () => {
    try {
      setLoading(true)
      const instagramUrl = socialMedia.instagram.label.replace('username', url)
      const socialData = {
        socialUrls: JSON.stringify({
          ...socialUrls,
          instagramUrl,
        }),
      }

      await axios.patch(ENDPOINTS.user.replace(':id', userId), socialData)

      setLoading(false)
      handleClose()
      handleAfterConnect(instagramUrl)
    } catch (error) {
      if (error?.response?.data?.message) {
        setLoading(false)
        setError(error?.response?.data?.message)
      } else {
        setLoading(false)
      }
    }
  }

  const connect = async () => {
    if (url) {
      await handleChangeSocialStatus()
    } else {
      setError(t('dashboard.msgInstagram'))
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openStatus}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <section className={classes.dialogContainer}>
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.dialogTitleSection}
        >
          <button onClick={handleClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>
        </DialogTitle>
        <Typography variant="h2">{t('shared.connectYourInstagram')}</Typography>
        <Typography variant="body1">
          {t('shared.enterYourInstagram')}
        </Typography>
        <div className={classes.container}>
          <div className={classes.imgBox}>
            <img src={socialMedia.instagram.logo} alt="logo" />
          </div>
          <input
            className={classes.socialInput}
            type="text"
            placeholder="username"
            value={url}
            onChange={e => setUrl(e.target.value)}
          />
        </div>
        <button
          onClick={connect}
          disabled={loading}
          className={classes.disabledButton}
        >
          {t('shared.connectAccount')}
        </button>
        <div className={classes.connectError}>{error}</div>
      </section>
    </Dialog>
  )
}

export default withTranslation()(ConnectInstagramModal)
