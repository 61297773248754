import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    ['& .grid-layout']: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0 -7.5px',
    },
    ['& .grid-item-dragging']: {
      position: 'absolute',
      left: 0,
      top: 0,
      background: '#fff',
      opacity: '0.7',
    },
    ['& .grid-item']: {
      width: '50%',
      padding: '0 7.5px',
      marginBottom: '40px',
      position: 'relative',
      display: 'none',
    },
    ['& .grid-item.grid-item-active']: {
      display: 'block',
    },
    ['& .grid-item.grid-item-selected']: {
      display: 'block',
    },
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      ['& .grid-layout']: {
        margin: '0 -10px',
      },
      ['& .grid-item']: {
        width: '33.33%',
        padding: '0 10px',
        marginBottom: '48px',
      },
    },
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      ['& .grid-layout']: {},
      ['& .grid-item']: {
        width: '25%',
      },
    },
  },
  card: {
    boxShadow: 'none',
  },
  cardArea: {
    ['& .MuiCardActionArea-focusHighlight']: {
      opacity: '0!important',
    },
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      position: 'static',
    },
  },
  modal: {
    outline: 'none',
    maxWidth: '100%',
    width: '40%',
    margin: '0 auto',
    ['& .ReactModal__Overlay']: {
      position: 'absolute',
    },
  },
  imageModal: {
    outline: 'none',
    textAlign: 'center',
    width: '40%',
    height: '50%',
    margin: 'auto',
    ['& .ReactModal__Overlay']: {
      position: 'absolute',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '60%',
      height: '60%',
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '80%',
      height: '60%',
    },
  },
  cardMediaWrapper: {
    overflow: 'hidden',
    position: 'relative',
    minHeight: '240px',
    width: '100%',
    border: '1px solid #f6f6f6',
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      minHeight: '300px',
    },
    [`@media (min-width: ${BREAKPOINTS.xmd}px)`]: {
      minHeight: '367px',
    },
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      minHeight: '285px',
    },
    [`@media (min-width: ${BREAKPOINTS.mdd}px)`]: {
      minHeight: '360px',
    },
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      minHeight: '285px',
    },
    [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
      minHeight: '320px',
    },
    [`@media (min-width: ${BREAKPOINTS.xml}px)`]: {
      minHeight: '400px',
    },
    [`@media (min-width: ${BREAKPOINTS.xxl}px)`]: {
      minHeight: '400px',
    },
    [`@media (min-width: ${BREAKPOINTS.xlg}px)`]: {
      minHeight: '440px',
    },
    [`@media (min-width: ${BREAKPOINTS.xxlg}px)`]: {
      minHeight: '480px',
    },
  },
  cardMediaWrapperInner: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    left: '50%',
    width: '100%',
    textAlign: 'center',
    ['& img']: {
      width: '100%',
      display: 'inline-block',
      height: 480,
      [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
        height: 240,
      },
    },
  },
  cardMedia: {
    objectFit: 'contain',
  },
  cardContent: {
    padding: 10,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      padding: '18px 0',
    },
  },
  cardIngMediaWrap: {
    display: 'inline-block',
    width: '50px !important',
    height: '50px !important',
  },
  cardIngText: {
    fontFamily: FONTS.Graphik.GraphikLight + ' !important',
    display: 'inline-block',
    fontSize: '0.75rem !important',
    lineHeight: '1.67',
    padding: '17px 0 0 11px',
    verticalAlign: 'top',
  },
  boxInfo: {
    display: 'block',
    width: '100%',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      float: 'left',
      padding: '0 5px 0 0',
      width: '62%',
    },
  },
  boxPrices: {
    width: '100%',
    display: 'flex',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'block',
      float: 'right',
      padding: '0 0 0 5px',
      width: '38%',
    },
  },
  saleBox: {
    display: 'flex',
    flexDirection: 'column',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'block',
    },
  },
  cardName: {
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    fontFamily: FONTS.Graphik.GraphikRegular + ' !important',
    fontSize: '10px !important',
    textTransform: 'lowercase',
    color: theme.palette.common.black,
    letterSpacing: '1px',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      fontSize: '12px !important',
    },
  },
  cardPrice: {
    fontFamily: FONTS.Graphik.GraphikRegular + ' !important',
    fontSize: '10px !important',
    textTransform: 'lowercase',
    color: theme.palette.common.black,
    letterSpacing: '1px',
    display: 'inline-block',
    float: 'left',
    padding: '0 5px 0 0',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      fontSize: '12px !important',
      padding: 0,
      display: 'block',
      float: 'none',
      textAlign: 'right',
    },
  },
  cardBrandName: {
    fontFamily: FONTS.Graphik.GraphikLight + ' !important',
    fontSize: '10px !important',
    textTransform: 'lowercase',
    color: theme.palette.common.black,
    letterSpacing: '1px',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      fontSize: '12px !important',
      float: 'none',
      display: 'block',
    },
  },
  cashPercentage: {
    fontFamily: FONTS.Graphik.GraphikRegular + ' !important',
    fontSize: '10px !important',
    textTransform: 'lowercase',
    color: theme.palette.common.black,
    letterSpacing: '1px',
    display: 'inline-block',
    float: 'right',
    padding: '0 0 0 5px',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      fontSize: '12px !important',
      float: 'none',
      display: 'block',
      padding: 0,
      textAlign: 'right',
    },
  },
  btnRemove: {
    position: 'absolute',
    left: 18,
    top: 10,
    cursor: 'pointer',
    width: 40,
    height: 40,
    borderRadius: '50%',
    background: '#fff',
    padding: 4,
    textAlign: 'center',
    zIndex: 99,
    userSelect: 'none',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: 40,
      height: 40,
      padding: 4,
    },
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 15,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    zIndex: 2,
    outline: 'none',
  },
  btnIcon: {
    width: 10,
    margin: 10,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: 14,
    },
  },
  moving: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rbga(0,0,0,0.01)',
    zIndex: 100,
    //cursor: 'move',
  },
  deleteButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 999,
  },
  SBCard: {
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      width: '100%',
      margin: '0 auto',
    },
  },
  checkboxWrapper: {
    position: 'absolute',
    left: 18,
    top: 10,
    cursor: 'pointer',
    zIndex: 110,
  },
  whiteCircle: {
    position: 'absolute',
    left: -2,
    top: -2,
    cursor: 'pointer',
    width: 50,
    height: 50,
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    background: 'rgba(255,255,255,0.5)',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
  },
  errImage: {
    objectFit: 'none',
    width: '80px !important',
  },
  errIgImage: {
    width: '50px !important',
    height: '50px !important',
  },
  choiceButton: {
    position: 'absolute',
    left: 20,
    top: 60,
    cursor: 'pointer',
    width: 40,
    height: 40,
    borderRadius: '50%',
    background: '#fff',
    padding: 7,
    textAlign: 'center',
    zIndex: 99,
    userSelect: 'none',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: 40,
      height: 40,
      padding: 7,
    },
  },
  updateButton: {
    background: 'black',
    color: COLORS.white,
    fontSize: '0.75rem',
    padding: 10,
  },
  updateImageButton: {
    // position: 'absolute',
    bottom: 10,
    right: 10,
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    color: COLORS.white,
    backgroundColor: COLORS.black,
    padding: '10px',
    // pointerEvents: 'none',
  },
}))
