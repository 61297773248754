import React, { useState, useEffect } from 'react'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { FormControl } from '@material-ui/core'
import Select from 'react-select'
import { BREAKPOINTS } from '../../../constants'

interface BrandsSortProps {
  count: number
  options: any[]
  selectedItem: number
  onSortChange: any
  t: TFunction
}

const SBSort = ({
  t,
  count,
  options,
  onSortChange,
  selectedItem,
  ...props
}: BrandsSortProps) => {
  const classes = styles()
  const [selectedOption, setSelectedOption] = useState(selectedItem)

  const handleChange = (event: any) => {
    onSortChange(event)
  }

  useEffect(() => {
    setSelectedOption(selectedItem)
  }, [selectedItem])

  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'none',
      width: '210px',
      padding: 0,
      marginLeft: 10,
      cursor: 'pointer',
      border: 'none !important',
      boxShadow: 'none',
      minHeight: 0,
      '&:hover': {
        border: 'none !important',
      },
    }),

    option: styles => {
      return {
        ...styles,
        fontFamily: 'Schnyder XL Light',
        fontSize: '1.25rem',
        textAlign: 'left',
        padding: '0 0 0 10px',
        [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
          fontSize: '1.875rem',
        },
      }
    },

    valueContainer: styles => ({
      height: 47,
    }),

    singleValue: styles => ({
      ...styles,
      top: 'unset',
      bottom: '-12px',
      fontFamily: 'Schnyder XL Light',
      fontSize: '1.25rem',
      fontWeight: 400,
      [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
        fontSize: '1.875rem',
        bottom: '-19px',
      },
    }),

    placeholder: styles => ({
      display: 'none',
    }),

    input: styles => ({
      color: 'transparent',
    }),

    menu: styles => ({
      ...styles,
      width: 250,
      right: 0,
      zIndex: 120,
    }),
  }

  return (
    <div className={classes.wrapper}>
      <FormControl className={classes.formControl}>
        <h4 className={classes.sortByText}>{t('shared.sortBy')}</h4>
        <Select
          defaultValue={selectedOption}
          value={selectedOption}
          className={classes.selectList}
          onChange={handleChange}
          options={options}
          styles={colourStyles}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
        />
      </FormControl>
    </div>
  )
}

export default withTranslation()(SBSort)
