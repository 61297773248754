import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { styles } from './styles'
import { COLORS } from './../../constants'

const LoadingScreen = () => {
  const classes = styles()
  return (
    <div className={classes.loading}>
      <CircularProgress style={{ color: COLORS.black }} size={40} />
    </div>
  )
}

export default LoadingScreen
