import React from 'react'
import { withTranslation } from 'react-i18next'
import Layout from '../../layouts/defaultLayout'
import ListingBoard from './listingBoard'
import CHRSectionContainer from './../../components/sectionContainer'
import SEO from '../seo'
import { styles } from './styles'
import { TFunction } from 'i18next'

interface ShoppingBoardPageProps {
  data: any
  t: TFunction
}

const ShoppingBoardPage = ({ data, t }: ShoppingBoardPageProps) => {
  const classes = styles()
  return (
    <>
      <Layout>
        <SEO
          title={t('shoppingBoard.metaTitle')}
          description={t('shoppingBoard.metaDescription')}
          // image={`${process.env.GATSBY_SITE_URL}${data?.metaImage?.childImageSharp?.fluid.src}`}
        />
        <CHRSectionContainer customStyle={classes.wrapper}>
          <ListingBoard
            image={data.shoppingBoardBanner.childImageSharp.fluid}
          />
        </CHRSectionContainer>
      </Layout>
    </>
  )
}
export default withTranslation()(ShoppingBoardPage)
